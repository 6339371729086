/* Tailwind Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Fonts */
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

/* Base Styles */
@layer base {
  body {
    @apply font-roboto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-oswald;
  }
}

/* Your existing styles */
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
}

/* Smooth transitions for the menu */
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Ensure the menu appears above other content */
.z-50 {
  z-index: 50;
}
